import Paragraph from "@editorjs/paragraph";

class CustomParagraph extends Paragraph {
  constructor({ data, config, api, readOnly }) {
    super({ data, config, api, readOnly });
    this._element.addEventListener('keydown', this.onKeyDown.bind(this));
    const timeUp = () => {setTimeout(this.onPaste.bind(this), 10)};
    this._element.addEventListener('paste', timeUp);
  }

  static get enableLineBreaks() {
    return true;
  }

  static get toolbox() {
    return {};
  }


  static get sanitize() {
    return {
      text: {
        br: true,
        p: true,
        b: true,
        i: true,
      }
    };
  }

  static get pasteConfig() {
    return {
      tags: ['P'],
      }
  }

  onKeyDown(e){
    if(e.key === 'Enter' && !e.shiftKey && !e.isComposing){
      e.stopPropagation();
      e.preventDefault();

      // 末尾に改行が複数ないと表示に不具合がおきるので追加
      if (!this._element.innerHTML.match(/<br><br>$/)) {
        this._element.appendChild(document.createElement('br'));
      }

      const selection = window.getSelection(),
            range = selection.getRangeAt(0),
            del = document.createElement('br');

      del.appendChild(range.extractContents());
      range.deleteContents();
      range.insertNode(del);
      range.setStartAfter(del);
      range.setEndAfter(del);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  validate(){
    const maxLength = 10000;
    if (this._element.innerText.length >= maxLength){
      swal({ title: '最大文字数を超えています', text: maxLength + '以内で入力してください' });
      this._element.innerText = this._element.innerText.slice(0, maxLength - 1);
      return false;
    }

    return true;
  }

}

export default CustomParagraph;
