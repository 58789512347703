import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {
  PreviewScreenProps, PreviewProps, SelectedCompaniesProps,
  ModalButtonProps, OpenModalScreenProps, SubmitButtonProps,
} from '../../types/sharings';
import { ModalScreen } from './modal_screen';

export const Preview: React.FC<PreviewProps> = ({ image, title, blockData }): JSX.Element => (
  <>
    <div className="mx-auto">{ image && <img src={image} width="100%" alt="" /> }</div>
    <h2 className="my-3 font-title">{ title }</h2>
    { blockData?.map((item) => (
      <div className="my-3">
        {/* eslint-disable-next-line */}
        { item.type === 'header' ? <h3><div className="preview-header font-header header-decoration" dangerouslySetInnerHTML={{ __html: item.data.text }} /></h3> : null }
        {/* eslint-disable-next-line */}
        { item.type === 'paragraph' ? <div className="preview-content font-paragraph" dangerouslySetInnerHTML={{ __html: item.data.text }} /> : null }
        { item.type === 'image' ? (
          <div className="text-center my-3">
            { item.data.file.url && <img src={item.data.file.url} width="100%" alt="" /> }
          </div>
        ) : null }
      </div>
    ))}
  </>
);

export const SelectedCompanies: React.FC<SelectedCompaniesProps> = ({
  companies,
}): JSX.Element => {
  const isCheckedCompany = companies?.some((c) => c?.checked === true);

  return (
    <div className="card card-shadow mx-auto my-auto">
      <div className="card-body">
        <div className="py-3">
          <div className="card-body">
            <div className="row">
              <div className="card-title-border-left">送信先</div>
            </div>
            <div className="row py-3">
              { isCheckedCompany === true
                ? (companies?.map((company) => (
                  company.checked ? <div className="col-6">{ company.name }</div> : null)))
                : <div className="col-6">送信先が選択されていません</div> }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OpenModalButton: React.FC<ModalButtonProps> = ({
  handleModalShow,
}): JSX.Element => (
  <button
    type="button"
    className="btn btn-primary px-5 ml-2"
    onClick={handleModalShow}
  >
    企業選択
  </button>
);

const OpenModalScreen: React.FC<OpenModalScreenProps> = ({
  companies, changeShowType, handleCompanyChecked, handleCompaniesAllCheck, checkOff,
}): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (): void => setShow(false);
  const handleShow = (): void => setShow(true);

  return (
    <>
      <OpenModalButton handleModalShow={handleShow} />
      <ModalScreen
        show={show}
        handleModalClose={handleClose}
        companies={companies}
        checkOff={checkOff}
        changeShowType={changeShowType}
        handleCompanyChecked={handleCompanyChecked}
        handleCompaniesAllCheck={handleCompaniesAllCheck}
      />
    </>
  );
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onSubmit, showType,
}): JSX.Element => (
  <div className="col-6 text-right sharings">
    { showType === 'preview'
      ? <button type="button" className="btn btn-primary px-5 ml-2" onClick={onSubmit}>送信</button>
      : <button type="button" className="btn btn-default action-btn ml-2" onClick={onSubmit}>下書き保存</button> }
  </div>
);

export const PreviewScreen: React.FC<PreviewScreenProps> = ({
  show, handlePreviewClose, image, title, blockData, companies, onSubmit, changeEditType,
  checkOff, changeShowType, handleCompanyChecked, handleCompaniesAllCheck, isGeneralMode, showType,
}): JSX.Element => {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden bg-gray-600 bg-opacity-75" open={open} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-5 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-4xl">
                <div className="h-full flex flex-col pt-20 pb-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6 bg-gray-50 pt-2 pb-4">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">プレビュー</Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white text-gray-400 hover:text-gray-500"
                          onClick={() => { handlePreviewClose(); changeEditType(); }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-4 sm:px-6">
                    <div className="card card-shadow mx-auto my-auto">
                      <div className="card-body mx-5 my-4">
                        <div className="sharings">
                          <Preview image={image} title={title} blockData={blockData} />
                        </div>
                      </div>
                    </div>
                  </div>
                  { !isGeneralMode ? <div className="flex-shrink-0 px-4 border-t border-gray-200 py-4 sm:px-6"><SelectedCompanies companies={companies} /></div> : '' }
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-4 sm:px-6">
                    <div className="row flex justify-end">
                      <div className="col-6 text-left sharings">
                        <button type="button" className="btn btn-default action-btn" onClick={() => { handlePreviewClose(); changeEditType(); }}>記事の編集に戻る</button>
                        { !isGeneralMode ? <OpenModalScreen companies={companies} checkOff={checkOff} changeShowType={changeShowType} handleCompanyChecked={handleCompanyChecked} handleCompaniesAllCheck={handleCompaniesAllCheck} /> : '' }
                      </div>
                      <SubmitButton onSubmit={onSubmit} showType={showType} />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
