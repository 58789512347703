import Header from "@editorjs/header";

class CustomHeader extends Header {
  constructor({ data, config, api, readOnly }) {
    super({ data, config, api, readOnly });
    this._element.addEventListener('keydown', this.onKeyDown.bind(this));
    const timeUp = () => {setTimeout(this.onPaste.bind(this), 10)};
    this._element.addEventListener('paste', timeUp);
  }

  static get enableLineBreaks() {
    return true;
  }

  static get sanitize() {
    return {
      text: {
        h3: {},
        p: {},
        br: {}
      }
    };
  }

  static get toolbox() {
    return {};
  }

  static get pasteConfig() {
    return false;
  }

  onKeyDown(e){
    if(e.key === 'Enter' && !e.shiftKey && !e.isComposing){
      e.stopPropagation();
      e.preventDefault();

      // 末尾に改行が複数ないと表示に不具合がおきるので追加
      if (!this._element.innerHTML.match(/<br><br>$/)) {
        this._element.appendChild(document.createElement('br'));
      }

      const selection = window.getSelection(),
            range = selection.getRangeAt(0),
            del = document.createElement('br');

      del.appendChild(range.extractContents());
      range.deleteContents();
      range.insertNode(del);
      range.setStartAfter(del);
      range.setEndAfter(del);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);

    }
  }

  validate(){
    const maxLength = 10000;
    if (this._element.innerText.length >= maxLength){
      swal({ title: '最大文字数を超えています', text: maxLength + '以内で入力してください' });
      this._element.innerText = this._element.innerText.slice(0, maxLength - 1);
      return false;
    }

    return true;
  }

  onPaste() {
    this._element.innerHTML = this.api.sanitizer.clean(this._element.innerHTML, this.constructor.sanitize.text);
  }

}

export default CustomHeader;
