import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Block, Company, FormProps } from '../types/sharings';
import { requestApi } from '../shared/requests';
import { handleError } from '../shared/common';
import { ActionButtons, Inputs } from '../components/sharings/sharings';
// import { Preview, SelectedCompanies } from '../components/sharings/preview_screen';
// import { Companies } from '../components/sharings/modal_screen';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Loading = require('lib/loading');

const validateDragAndDropFile = (file) => {
  const allowedExtensions = ['jpg', 'jpeg', 'png'];

  const getExtension = (filename) => {
    const splitedFilename = filename.lastIndexOf('.');
    if (splitedFilename === -1) return '';
    return filename.slice(splitedFilename + 1);
  };

  const checkExt = (filename) => {
    const ext = getExtension(filename).toLowerCase();
    return allowedExtensions.indexOf(ext) !== -1;
  };

  if (file.length === 0) {
    throw new Error('画像ファイル（.jpg、.jpeg、.png）を必ず選択してください');
  }

  if (!checkExt(file[0].name)) {
    throw new Error('画像ファイル（.jpg、.jpeg、.png）はいずれかでお願いいたします。');
  }

  if (file[0].size > 20000000) {
    throw new Error('画像ファイル（.jpg、.jpeg、.png）は20MB以内まででお願いいたします');
  }
};

/* Form生成関数 */
const Form: React.FC<FormProps> = ({
  token, sharing, loadedCompanies, endpoint, returnTo,
  method, isdraft, formTitle, userType,
}): JSX.Element => {
  const [title, setTitle] = useState<string>(sharing?.title ? sharing?.title : '');
  const [image, setImage] = useState<string>(sharing?.image ? sharing.image : '');
  const [isImageSending, setIsImageSending] = useState<boolean>(false);
  const [showType, setShowType] = useState<string>('edit');
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const [blockData, setBlockData] = useState<Block[]>(
    sharing?.body ? JSON.parse(sharing.body) : [],
  );
  const [addCheckedToCompanies, setAddCheckedToCompanies] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>(loadedCompanies);
  const isGeneralMode: boolean = endpoint.length > 0;
  const [uploadPath] = useState(
    userType === 'consultant' ? '/consultants/sharings' : '/systems/sharing_templates',
  );

  // edit, select, preview
  const toggleShow = (type: string): void => {
    if (!isGeneralMode) {
      const company: Company = companies.find((c) => c?.checked === true);
      if (type === 'preview' && company === undefined) {
        handleError('顧客を選択してください。');
      } else {
        setShowType(type);
      }
    } else {
      setShowType(type);
    }
  };

  const saveAsDraft: boolean = showType === 'preview' ? !(!sharing?.id || isGeneralMode) : (!isGeneralMode || isdraft);
  const handleClick = (type): void => {
    editorInstance.blocks.insert(
      type,
      {},
      {},
      (editorInstance.blocks.getBlocksCount() + 1),
      true,
    );
  };

  // companiesにcheckboxのckeckedをfalseで代入
  const handleCheckedOfCompanies = (): void => {
    if (sharing?.id === undefined || addCheckedToCompanies) {
      return;
    }
    const modifiedCompanies: Company[] = [];
    for (let i = 0; loadedCompanies.length > i; i += 1) {
      // TODO(okubo): useStateで管理する
      // eslint-disable-next-line no-param-reassign
      loadedCompanies[i].checked = false;
      modifiedCompanies.push(loadedCompanies[i]);
    }
    setCompanies(modifiedCompanies);
    setAddCheckedToCompanies(true);
  };

  useEffect(() => {
    let unmounted = false;
    const f = async () => {
      if (!unmounted) { handleCheckedOfCompanies(); }
    };
    f();
    return () => { unmounted = true; };
  }, []);

  /* title, imageをuseStateにいれるための関数 */
  const handleTitle = (value) => { setTitle(value); };

  const onDrop = (file): void => {
    try {
      validateDragAndDropFile(file);
      setIsImageSending(true);
      Loading.start();
      const formData = new FormData();
      formData.append('sharing[image]', file[0]);
      formData.append('authenticity_token', token);

      requestApi(formData, 'post', `${uploadPath}/upload_image`)
        .then((res: any) => { // TODO(okubo): 後々型を入れる
          setIsImageSending(true);
          setImage(res.data.response.image);
        })
        .catch((error) => handleError(error))
        .finally(() => {
          setIsImageSending(false);
          Loading.stop();
        });
    } catch (e) { handleError(e); }
  };

  /* 削除関数 */
  const remove = (index: number): void => {
    /* メイン画像削除 */
    if (index === null) {
      const params = new URLSearchParams();
      params.append('authenticity_token', token);
      params.append('link', image);
      requestApi(params, 'delete', `${uploadPath}/delete_image`)
        .then(() => setImage(''))
        .catch((error) => handleError(error.response.data.message));
    }
  };

  const handleCompanyChecked = (id: number, value: boolean): void => {
    const newArray = [...companies];
    const pickedCompay = companies.find((x) => x.id === id);
    const index = companies.indexOf(pickedCompay);
    pickedCompay.checked = value;
    newArray[index] = pickedCompay;
    setCompanies(newArray);
  };

  const handleCompaniesAllCheck = (bool: boolean): void => {
    const newArray: Company[] = [...companies];
    for (let i = 0; newArray.length > i; i += 1) {
      if (newArray[i].checked !== bool) {
        newArray[i].checked = bool;
      }
    }
    setCompanies(newArray);
  };

  const checkOff = (): void => {
    const newArray: Company[] = [...companies];
    for (let i = 0; newArray.length > i; i += 1) {
      newArray[i].checked = false;
    }
    setCompanies(newArray);
  };

  // 多重クリック防止処理のための関数
  const processing = useRef(false);
  /* Form送信関数 */
  const onSubmit = (): void => {
    // 処理中かどうか
    if (processing.current) return;

    // TODO, sharingがあればputにする
    const params = {
      authenticity_token: token,
      save_as_draft: saveAsDraft,
      sharing: {
        id: '',
        title,
        image,
        body: '',
        confirmation: false, // 企業にメール送信するか？のフラグcreateではfalseで企業を選択していればtrueで
        sendable_company_targets: [], // 送信先の企業IDを挿入する
      },
    };

    if (processing.current === false) {
      editorInstance.save().then((res) => {
        for (let i = 0; res.blocks.length > i; i += 1) {
          if (res.blocks[i].type === 'image') {
            // なぜかamp; が入ってしまうので原因を解決する
            // eslint-disable-next-line no-param-reassign
            res.blocks[i].data.file.url = res.blocks[i].data.file.url.replace('amp;', '');
          }
        }
        params.sharing.body = JSON.stringify(res.blocks);
        if (!isGeneralMode) {
          const company = companies.find((c) => c?.checked === true);
          // cはtrueのcompanyが一つでもあるかどうか
          if (company !== undefined && showType === 'preview') {
            params.sharing.confirmation = true;
            for (let i = 0; companies.length > i; i += 1) {
              if (companies[i].checked === true) {
                params.sharing.sendable_company_targets.push(companies[i].id);
              }
            }
          }
        }

        if (params.sharing.confirmation === false) {
          // TODO(okubo): useStateで管理する
          // eslint-disable-next-line no-param-reassign
          returnTo = returnTo.concat('?type=draft');
        }

        if (endpoint.length) {
          requestApi(params, method, endpoint)
            // TODO(okubo): useStateで管理する
            // eslint-disable-next-line no-restricted-globals,no-return-assign
            .then(() => location.href = returnTo)
            .catch((error) => handleError(error.response.data.message));
        } else if ((sharing?.id === undefined) || (sharing?.id === null)) {
          requestApi(params, 'post', '/consultants/sharings')
            // eslint-disable-next-line no-restricted-globals,no-return-assign
            .then(() => location.href = returnTo)
            .catch((error) => handleError(error.response.data.message));
        } else {
          params.sharing.id = (sharing.id).toString();
          requestApi(params, 'put', `/consultants/sharings/${sharing.id}`)
            // eslint-disable-next-line no-restricted-globals,no-return-assign
            .then(() => location.href = returnTo)
            .catch((error) => handleError(error.response.data.message));
        }
        // 処理中フラグを上げる
        processing.current = true;
      });
    }
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 5000);
  };

  // eslint-disable-next-line no-restricted-globals
  const onCancel = () => { location.reload(); };

  const changeShowType = (): void => {
    const isData = blockData?.some((b) => b.data.text !== '');
    if (!isGeneralMode) {
      const company: Company = companies.find((c) => c?.checked === true);
      if (company === undefined || image === '' || title === '' || isData === false) {
        setShowType('edit');
      } else {
        setShowType('preview');
      }
    } else if (image === '' || title === '' || isData === false) {
      setShowType('edit');
    } else {
      setShowType('preview');
    }
  };

  const changeEditType = (): void => {
    setShowType('edit');
  };

  return (
    <>
      <div className="card card-shadow col-lg-12 col-xl-12 mx-auto">
        <div className="card-body">
          <div className="row pb-3">
            <div className="col-12">
              <div className="card-title-border-left">
                {formTitle || '新規作成'}
              </div>
              <div className="py-3 card-max-width">
                <div>
                  <Inputs
                    image={image}
                    isImageSending={isImageSending}
                    title={title}
                    handleTitle={handleTitle}
                    handleClick={handleClick}
                    onSubmit={onSubmit}
                    remove={remove}
                    onDrop={onDrop}
                    editorInstance={editorInstance}
                    setEditorInstance={setEditorInstance}
                    blockData={blockData}
                    setBlockData={setBlockData}
                    token={token}
                    uploadPath={uploadPath}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ActionButtons
        sharing={sharing}
        showType={showType}
        toggleShow={toggleShow}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isGeneralMode={isGeneralMode}
        isdraft={isdraft}
        image={image}
        title={title}
        blockData={blockData}
        companies={companies}
        checkOff={checkOff}
        changeEditType={changeEditType}
        changeShowType={changeShowType}
        handleCompanyChecked={handleCompanyChecked}
        handleCompaniesAllCheck={handleCompaniesAllCheck}
      />
    </>
  );
};

const App = ({
  token, sharing, loadedCompanies, endpoint, returnTo, method, isdraft, formTitle, userType,
}) => (
  <Form
    token={token}
    sharing={sharing}
    loadedCompanies={loadedCompanies}
    method={method}
    isdraft={isdraft}
    endpoint={endpoint}
    returnTo={returnTo}
    formTitle={formTitle}
    userType={userType}
  />
);

$(() => {
  const elm = document.querySelector('#sharingsForm');
  if (!elm) {
    return;
  }

  const token = JSON.parse(elm.getAttribute('token'));
  const sharing = JSON.parse(elm.getAttribute('sharing'));
  const loadedCompanies = JSON.parse(elm.getAttribute('companies'));
  const method = elm.getAttribute('method'); // API request method (optional)
  const isdraft = elm.getAttribute('isdraft') === 'true'; // status (optional)
  const endpoint = elm.getAttribute('endpoint') || ''; // API endpoint (optional)
  const returnTo = elm.getAttribute('returnto') || '/consultants/sharings'; // retrun to address (optional)
  const formTitle = elm.getAttribute('formTitle'); // form title (optional)
  const userType = elm.getAttribute('userType');

  ReactDOM.render(
    <App
      token={token}
      sharing={sharing}
      loadedCompanies={loadedCompanies}
      method={method}
      isdraft={isdraft}
      endpoint={endpoint}
      returnTo={returnTo}
      formTitle={formTitle}
      userType={userType}
    />,
    elm,
  );
});
