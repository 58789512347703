import React, { Fragment, useState } from 'react';
import Dropzone from 'react-dropzone';
import EditorJs from 'react-editor-js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CustomHeader from 'editorjs/custom_header';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CustomParagraph from 'editorjs/custom_paragraph';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CustomImage from 'editorjs/custom_image';
import { handleError } from '../../shared/common';

import {
  ActionButtonsProps,
  DragAndDropImageInputProps,
  EditorProps,
  ImageInputProps,
  InputProps, InputsProps,
  LabelProps, PreviewButtonProps, OpenPreviewScreenProps,
} from '../../types/sharings';
import { requestApi } from '../../shared/requests';
import { PreviewScreen } from './preview_screen';

export const Label: React.FC<LabelProps> = ({ name }): JSX.Element => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="text-left">{name}</label>
);

/* HTMLのタグ生成 */
export const Input: React.FC<InputProps> = ({ handleTitle, value }): JSX.Element => (
  <input
    id="titleInput"
    type="text"
    className="form-control my-1 input-title"
    onChange={(e) => handleTitle(e.target.value)}
    maxLength={256}
    value={value}
  />
);

export const DragAndDropImageInput: React.FC<DragAndDropImageInputProps> = ({
  image, onDrop, remove,
}): JSX.Element => (
  <Dropzone
    accept="image/jpeg, image/jpg, image/png"
    onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
  >
    {({ getRootProps, getInputProps }) => (
      <div className="image-wrapper">
        {image
          && (
            <div role="presentation" className="delete-btn p-1" onClick={() => remove(null)}>
              削除
              <i className="fa fa-trash" />
            </div>
          )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <section className="info image-zone" style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }} {...getRootProps()}>
          <div className="content">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input {...getInputProps()} />
            <p className="font-16 mb-2 font-weight-bold">ここにファイル(.jpg、.jpeg、.png)をドロップ</p>
            <p className="mb-2">または</p>
            <div className="mb-2 btn btn-file-select px-4 font-weight-bold">
              ファイル(.jpg、.jpeg、.png)を選択
            </div>
            <p className="mb-0" />
          </div>
        </section>
      </div>
    )}
  </Dropzone>
);

export const ImageInput: React.FC<ImageInputProps> = ({
  image, onDrop, isImageSending, remove,
}): JSX.Element => {
  const [onHover, setOnHover] = useState<boolean>(false);

  return (
    <div onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
      {image
        ? (
          <div className="image-wrapper">
            <div className="info image-zone" style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}>
              <div className="on-image-dd" style={{ display: onHover ? 'block' : 'none' }}>
                <DragAndDropImageInput image={image} onDrop={onDrop} remove={remove} />
              </div>
            </div>
          </div>
        )
        : <DragAndDropImageInput image={image} onDrop={onDrop} remove={remove} />}
      {isImageSending && <p>画像を送信中です.....</p>}
    </div>
  );
};

export const Editor: React.FC<EditorProps> = ({
  editorInstance, setEditorInstance, blockData, setBlockData, token, uploadPath,
}): JSX.Element => {
  const validateFile = (file) => {
    const allowedExtensions = ['jpg', 'jpeg', 'png'];

    const getExtension = (filename) => {
      const splitedFilename = filename.lastIndexOf('.');
      if (splitedFilename === -1) return '';
      return filename.slice(splitedFilename + 1);
    };
    const checkExt = (filename) => {
      const ext = getExtension(filename).toLowerCase();
      return allowedExtensions.indexOf(ext) !== -1;
    };
    if (file.length === 0) {
      handleError('画像ファイル（.jpg、.jpeg、.png）を必ず選択してください');
    }
    if (!checkExt(file[0].name)) {
      handleError('画像ファイル（.jpg、.jpeg、.png）はいずれかでお願いいたします。');
    }
    if (file[0].size > 20000000) {
      handleError('画像ファイル（.jpg、.jpeg、.png）は20MB以内まででお願いいたします');
    }
  };

  const uploadByFile = async (file: File): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append('sharing[image]', file);
      formData.append('authenticity_token', token);
      const response: any = await requestApi(formData, 'post', `${uploadPath}/upload_image`);
      return {
        success: 1,
        file: {
          url: response.data.response.image,
        },
      };
    } catch (e) {
      validateFile([file]);
      $(document).ready(() => {
        document.getElementsByClassName('cdx-notify')[0].remove();
      });
    }
    return null;
  };

  const editorInstanceToObject = (): void => {
    setTimeout(() => {
      editorInstance.save().then((res) => {
        for (let i = 0; res.blocks.length > i; i += 1) {
          if (res.blocks[i].type === 'image' && res.blocks[i].data.file !== {}) {
            // なぜかamp; が入ってしまうので原因を解決する
            res.blocks[i].data.file.url = res.blocks[i].data.file.url.replace('amp;', '');
          }
        }
        setBlockData(res.blocks);
      });
    }, 200);
  };

  const config = {
    paragraph: {
      class: CustomParagraph,
      inlineToolbar: ['link', 'bold', 'italic'],
      config: {
        placeholder: '内容',
      },
    },
    image: {
      class: CustomImage,
      config: {
        // 画像を選択している際の選択肢及びcaptionはcssでdisableにしている。
        uploader: { uploadByFile },
      },
    },
    header: {
      class: CustomHeader,
      config: {
        defaultLevel: 3,
        placeholder: '見出し',
      },
    },
  };

  const i18n = {
    messages: {
      ui: {
        blockTunes: {
          toggler: {
            'Click to tune': '操作',
            'or drag to move': 'или перетащите',
          },
        },
        inlineToolbar: {
          converter: {
            'Convert to': '変換',
          },
        },
        toolbar: {
          toolbox: {
            Add: '追加',
          },
        },
      },
      toolNames: {
        Text: '内容',
        Heading: '見出し',
        Link: 'リンク',
        Bold: '太文字',
        Italic: 'イタリック',
        Image: '画像',
      },
      tools: {
        link: {
          'Add a link': 'https://hrbase.jp/',
        },
        image: {
          'Select an Image': '画像ファイル(.jpg、.jpeg、.png)を選択',
        },
        stub: {
          'The block can not be displayed correctly.': 'Блок не может быть отображен',
        },
      },
      blockTunes: {
        delete: {
          Delete: '削除',
        },
        moveUp: {
          'Move up': '上に移動',
        },
        moveDown: {
          'Move down': '下に移動',
        },
      },
    },
  };

  return (
    <EditorJs
      instanceRef={(instance) => setEditorInstance(instance)}
      tools={config}
      defaultBlock="paragraph"
      i18n={i18n}
      onChange={editorInstanceToObject}
      data={{ blocks: blockData }}
    />
  );
};

export const Inputs: React.FC<InputsProps> = ({
  image, isImageSending, title, handleTitle, onSubmit, remove,
  onDrop, editorInstance, setEditorInstance, blockData, setBlockData, token, handleClick,
  uploadPath,
}): JSX.Element => (
  <form onSubmit={() => onSubmit}>
    <div className="form-group">
      <Label name="サムネイル画像" />
      <div className="text-center">
        <ImageInput image={image} onDrop={onDrop} isImageSending={isImageSending} remove={remove} />
      </div>
    </div>
    <div className="form-group mt-4">
      <Label name="タイトル" />
      <Input handleTitle={handleTitle} value={title} />
      <div className="form-group body">
        <Label name="本文" />
        <Editor
          editorInstance={editorInstance}
          setEditorInstance={setEditorInstance}
          blockData={blockData}
          setBlockData={setBlockData}
          token={token}
          uploadPath={uploadPath}
        />
        <div className="row">
          <div className="col-12 text-center">
            <div className="btn-group">
              <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-plus-circle fa-2x btn-default" />
              </div>
              <div className="dropdown-menu">
                <button type="button" className="dropdown-item" onClick={handleClick.bind(this, 'header')}>
                  <i className="fas fa-heading" />
                  見出し
                </button>
                <button type="button" className="dropdown-item" onClick={handleClick.bind(this, 'paragraph')}>
                  <i className="fas fa-font" />
                  本文
                </button>
                <button type="button" className="dropdown-item" onClick={handleClick.bind(this, 'image')}>
                  <i className="fas fa-image" />
                  画像
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
);

const OpenPreviewButton: React.FC<PreviewButtonProps> = ({
  handlePreviewShow, changeShowType,
}): JSX.Element => (
  <div className="row mt-3">
    <div className="col-12 text-center">
      <button type="button" className="btn btn-primary address-select-btn" onClick={() => { handlePreviewShow(); changeShowType(); }}>プレビュー</button>
    </div>
  </div>
);

const OpenPreviewScreen: React.FC<OpenPreviewScreenProps> = ({
  image, title, blockData, companies, onSubmit, checkOff, changeEditType,
  changeShowType, handleCompanyChecked, handleCompaniesAllCheck, isGeneralMode, showType,
}): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (): void => setShow(false);
  const handleShow = (): void => setShow(true);

  return (
    <>
      <OpenPreviewButton
        handlePreviewShow={handleShow}
        changeShowType={changeShowType}
      />
      <PreviewScreen
        show={show}
        handlePreviewClose={handleClose}
        image={image}
        title={title}
        blockData={blockData}
        companies={companies}
        onSubmit={onSubmit}
        checkOff={checkOff}
        isGeneralMode={isGeneralMode}
        showType={showType}
        changeEditType={changeEditType}
        changeShowType={changeShowType}
        handleCompanyChecked={handleCompanyChecked}
        handleCompaniesAllCheck={handleCompaniesAllCheck}
      />
    </>
  );
};

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  onSubmit, onCancel, image, title, blockData, companies, checkOff, changeEditType,
  changeShowType, handleCompanyChecked, handleCompaniesAllCheck, isGeneralMode, showType,
}): JSX.Element => (
  <div className="row mt-5">
    <div className="col-12 text-center">
      <div className="row">
        <div className="col-12 text-center">
          <button type="button" className="btn btn-default action-btn" onClick={onCancel}>キャンセル</button>
          <button type="button" className="btn btn-default action-btn" onClick={onSubmit}>下書き保存</button>
        </div>
      </div>
      <OpenPreviewScreen
        image={image}
        title={title}
        blockData={blockData}
        companies={companies}
        onSubmit={onSubmit}
        checkOff={checkOff}
        showType={showType}
        isGeneralMode={isGeneralMode}
        changeEditType={changeEditType}
        changeShowType={changeShowType}
        handleCompanyChecked={handleCompanyChecked}
        handleCompaniesAllCheck={handleCompaniesAllCheck}
      />
    </div>
  </div>
);
