module.exports = {
  start: function () {
    let loading = document.getElementsByClassName('preloader');
    loading[0].style.opacity = 0.5;
    loading[0].style.display = null;
  },
  stop: function () {
    let loading = document.getElementsByClassName('preloader');
    loading[0].style.display = 'none';
  }
};
