import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ModalScreenProps, CheckboxProps, CompaniesProps,
} from '../../types/sharings';

export const Checkbox: React.FC<CheckboxProps> = ({
  key, handleCompanyChecked, company,
}): JSX.Element => (
  <div key={key} className="col-6 company-selection">
    <input
      type="checkbox"
      className="picked-company-checkbox my-auto"
      onChange={(e) => handleCompanyChecked(company.id, e.target.checked)}
      checked={company?.checked}
    />
    <div className="picked-company my-auto">
      { company?.name }
      {' '}
    </div>
  </div>
);

export const Companies: React.FC<CompaniesProps> = (
  { handleCompanyChecked, handleCompaniesAllCheck, companies },
): JSX.Element => (
  <div className="card-body">
    <div className="row">
      <div className="col-12">
        <h4 className="card-title py-3" style={{ border: 'none' }}>送信する顧客を選択</h4>
      </div>
      <div className="col-12 my-auto">
        <div key="key" className="company-selection">
          <input type="checkbox" className="picked-company-checkbox my-auto" onChange={(e) => handleCompaniesAllCheck(e?.target?.checked)} />
          <div className="picked-company my-auto">すべて選択</div>
        </div>
      </div>
    </div>
    <div className="row py-3">
      {
        companies?.map((company) => (
          <Checkbox
            key={company.name}
            handleCompanyChecked={handleCompanyChecked}
            company={company}
          />
        ))
      }
    </div>
  </div>
);

export const ModalScreen: React.FC<ModalScreenProps> = ({
  show, handleModalClose, companies,
  changeShowType, handleCompanyChecked, handleCompaniesAllCheck, checkOff,
}): JSX.Element => {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-center bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  送信先を選択
                </Dialog.Title>
              </div>
              <div className="mt-2">
                <Companies
                  handleCompanyChecked={handleCompanyChecked}
                  handleCompaniesAllCheck={handleCompaniesAllCheck}
                  companies={companies}
                />
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense sharings">
                <button type="button" className="btn btn-default" onClick={() => { handleModalClose(); checkOff(); changeShowType(); }}>解除</button>
                <button type="button" className="btn btn-primary" onClick={() => { handleModalClose(); changeShowType(); }}>選択</button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
